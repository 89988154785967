.Header {
  background-color: white;
  box-shadow: 0 4px 50px 0 #00407d1a;
  height: 5rem;
}

.Header .navbar-collapse.collapse.show {
  box-shadow: 0 4px 50px 0 #00407d1a;
}

.Header .nav-link {
  color: var(--base-50) !important;
}

.Header .nav-link:hover {
  color: var(--base-50) !important;
}

.Header .nav-link.active {
  color: var(--base-50) !important;
  font-weight: bold;
}

.Header .nav-link span {
  /* width: 5rem; */
}

.Header .nav-link.active::after {
  content: "";
  border: 1px solid;
  width: 28px;
  margin: auto;
  border-radius: 10px;
  margin-top: 5px;
}

.Header .navbar-brand .nav-link.active::after,
.Header .navbar-brand .nav-link::after {
  content: "";
  border: 2px solid var(--primary-blue);
  width: 28px;
  margin: auto;
  border-radius: 10px;
  margin-top: 5px;
}

.Header .primary-blue-btn {
  border: 1px solid white !important;
  font-family: Mulish !important;
}

/* Section */
.section-title {
  color: white;
  font-size: 54px;
  font-weight: bold;
  font-family: Mulish;
  margin-bottom: 24px !important;
}

.section-subtitle {
  color: var(--base-100);
  font-size: 24px;
  font-weight: 400;
  font-family: Mulish;
}

.build-together .content-body {
  margin-bottom: 40px;
}

section .content-body {
  font-size: 20px;
  color: var(--base-100);
  text-align: justify;
  font-family: Urbanist !important;
}

section hr {
  color: transparent;
}

section.build-together {
  margin-top: 200px;
  margin-bottom: 200px;
}

section.half-bg {
  background-image: url(./assets/images/banners/4.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 50%;
}

section .banner-title {
  font-size: 4rem;
}

section.half-bg .section-title {
  margin-bottom: 24px !important;
}

section.half-bg button {
  margin-top: 40px !important;
}

section+section {
  margin-top: 200px;
}

.video-react {
  border-radius: 50px;
  overflow: hidden;
}

.video-react.video-react-fluid {
  box-shadow: 0 0 50px 0 #ffffff4a;
  z-index: 10 !important;
}

.video-react-big-play-button {
  background: var(--primary-blue) !important;
}

.video-react .video-react-big-play-button {
  background: var(--primary-blue) !important;
  border-color: var(--primary-blue) !important;
}

.video-react .video-react-big-play-button:before {
  color: white;
  font-size: 4rem;
  top: 26px;
}

.video-react .video-react-big-play-button:before {
  position: absolute;
  top: 28%;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-react-big-play-button {
  top: 40% !important;
  background: white !important;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
}

@media screen and (max-width: 992px) {
  section.half-bg {
    background-image: none;
  }
}

.service-workflow {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

/* Cards */

.ServiceCard.card .card-body .card-title,
.TeamMemberCard.card .card-body .card-title,
.PrinciplesCard .card-title {
  font-size: 20px;
  color: white;
  font-weight: bold;
  font-family: Mulish !important;
}

.check-list-title {
  font-size: 20px;
  color: white;
  font-weight: bold;
  font-family: Mulish !important;
}

.ServiceCard.card .card-body .card-subtitle,
.TeamMemberCard.card .card-body .card-subtitle,
.banner-subtitle,
.PrinciplesCard .card-subtitle {
  font-size: 16px;
  color: var(--base-100);
  font-weight: 100;
  line-height: 1.5;
}

.banner-subtitle {
  font-size: 20px !important;
}

.banner-item-1 {
  top: 1%;
  left: 20%;
}

.banner-item-2 {
  bottom: -5%;
  left: 25%;
}

.banner-item-3 {
  right: -6%;
  top: -14%;
  z-index: 1;
}

.banner-item-4 {
  top: -10%;
  left: -6%;
}

.banner-item-5 {
  bottom: -6%;
  right: -6%;
}

.message-p-content{
  border-left: 5px solid white;
  font-style: italic;
  font-family: Mulish !important;
}

.ceo-info .ceo-name {
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-family: Mulish !important;
}

.ceo-info .ceo-role {
  color: var(--base-100);
  font-size: 13px;
  font-weight: 400;
  font-family: Mulish !important;
}

.check-list-subtitle {
  font-size: 16px;
  color: var(--base-200);
  font-weight: 100;
  line-height: 1.5;
}

.primary-blue-btn {
  height: 48px;
  border: none !important;
  font-family: Mulish !important;
}

.banner-subtitle {
  margin-bottom: 40px;
}

.ServiceCard.card .card-body span {
  width: 76px;
  height: 76px;
  transition: 1s;
  background: #0A0219 !important;
}

.ServiceCard.card .card-body .nav-link {
  display: flex;
  justify-content: center;
  width: 45%;
  margin: auto;
  color: white !important;
  border-bottom: 1px solid var(--primary-blue);
  font-family: Mulish !important;
}

.ServiceCard.card .card-body .nav-link:hover {
  display: flex;
  justify-content: center;
  width: 35%;
  transition: 1s;
  margin: auto;
  border-bottom: 1px solid white;
}

.ServiceCard.card:hover .card-body span {
  background-color: white !important;
  transition: 1s;
  width: 76px;
  height: 76px;
}

.ServiceCard:hover span img {
  filter: invert(1);
  transition: 1s;
}

.ServiceCard:hover .nav-link {
  display: flex;
  justify-content: center;
  width: 35%;
  margin: auto;
  border-bottom: 1px solid white !important;
}

.ProjectCard {
  top: 5rem;
  left: -5rem;
  height: 85px;
  width: 171px;
  z-index: 15 !important;
}

.ProjectCard .project-card-title {
  font-size: 12px;
  color: var(--base-100);
  font-family: Mulish !important;
}

.ProjectCard .project-card-count {
  font-size: 24px;
  font-weight: 400;
  color: white;
  font-family: Mulish !important;
}

.IntroCard {
  width: 166px;
  height: 180px;
  bottom: -1rem;
  right: -5rem;
  z-index: 15 !important;
}

.IntroCard .introcard-info .name {
  font-size: 12px;
  font-weight: 700;
  color: white;
  font-family: Mulish !important;
}

.IntroCard .introcard-info .role {
  font-size: 12px;
  font-weight: 500;
  color: var(--base-100);
  font-family: Mulish !important;
}

.IntroCard .intro-content {
  font-size: 12px;
  color: white;
  font-weight: 300;
}

.accordion {
  --bs-accordion-bg: var(--primary-blue) !important;
}

.accordion-item .accordion-button {
  font-size: 24px;
  font-weight: 500;
  color: white;
  height: 124px;
}

.accordion-item:first-of-type .accordion-button[aria-expanded="true"],
.accordion-button:not(.collapsed),
button:focus:not(:focus-visible) {
  background: var(--primary-blue) !important;
  color: white !important;
  box-shadow: none !important;
}

.accordion-button::after {
  content: "+" !important;
  background-image: none !important;
}

.accordion-button:not(.collapsed)::after {
  content: "-" !important;
  background-image: none !important;
  transform: none !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-item {
  border-bottom: 1px solid var(--base-100) !important;
}

.accordion-item:last-of-type {
  border: none !important;
}

.accordion-body {
  color: var(--base-100);
}

.accordion {
  --bs-accordion-border-width : 0 !important
}

.ContactPage input.form-control{
  height: 50px;
  margin-bottom: 16px;  
}

.ContactPage .form-control{
  background-color: #0A0219;
  color: var(--base-100) !important;
  border-color: var(--base-400) !important;
}

.ContactPage .form-control::placeholder {
  color: var(--base-100) !important;
}

.ContactPage .form-control:focus {
  background-color: #0A0219;
  box-shadow: none;
}

.ContactPage .section-title {
  margin-bottom: 64px !important;
}

.list-icon {
  color: var(--secondary-green-500);
}

.service-workflow ul{
  list-style: none;
  padding-left: 0px !important;
  font-size: 20px;
  color: var(--base-100);
}


/* Footer */

.Footer {
  background-color: var(--primary-blue-50);
}

.Footer .content-body {
  color: var(--base-100);
  font-size: 14px;
}

.Footer .footer-section-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px !important;
}

.Footer .nav-link {
  color: var(--base-100);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px !important;
}

.Footer .nav-link:hover,
.Footer .nav-link.active {
  color: white;
}

.Footer .social-links {
  margin-top: 20px;
}

.Footer .social-links .nav-link img {
  margin-right: 10px;
}